<template>
  <div style="background: #f8f8f8">
    <!-- Mobile layout -->
    <div
      v-if="isMobile"
      style="
        display: flex;
        flex-direction: column;
        background: #f8f8f8;
        height: 100vh;
      "
    >
      <TheMobileHeader @toggleSidebar="toggleSidebar" />

      <div :class="['mobileMainContentArea']">
        <transition name="fade" mode="out-in">
          <router-view v-slot="{ Component, route }">
            <component :is="Component" :key="$route.path" />
          </router-view>
        </transition>
      </div>
      <TheSidebar v-show="showSidebar" />
    </div>

    <!-- Desktop layout -->
    <div v-else style="display: flex; background: #f8f8f8">
      <TheSidebar />

      <div style="display: grid; grid-template-rows: 90px auto 100px">
        <TheHeader />
        <div
          class="mainContentArea"
          :class="{
            'sidebar-minimized': minimize,
            newsPage: $route.name == 'News',
          }"
        >
          <transition name="fade" mode="out-in">
            <router-view v-slot="{ Component, route }">
              <component :is="Component" :key="$route.path" />
            </router-view>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheFooter from "./TheFooter";
import ThePageHeader from "./ThePageHeader";
import TheHeader from "./TheHeader";
import TheMobileHeader from "./TheMobileHeader";
import { IS_MZ_BRANDING } from "@/constants/config";
import { UsersService } from "../services/users";
import { mapState } from "vuex";
import { useGlobalStore } from "../stores/store";

export default {
  name: "TheContainer",
  data() {
    return {
      globalStore: useGlobalStore(),
      IS_MZ_BRANDING,
      amIIn: false,
      showLang: false,
      isMobile: false,
      showSidebar: false,
    };
  },
  components: {
    TheSidebar,
    TheFooter,
    ThePageHeader,
    TheHeader,
    TheMobileHeader,
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData,
      isUserAdmin: (state) => state.isUserAdmin,
      minimize: (state) => state.sidebarMinimize,
    }),

    shouldDisplayProfile() {
      return this.$route.path !== "/profile";
    },
  },
  mounted() {
    this.checkIfMobile();
    window.addEventListener("resize", this.checkIfMobile);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIfMobile);
  },
  created() {
    this.currentUser = this.userData;
    this.$i18n.locale = this.currentUser.language
      ? this.currentUser.language
      : "en";
    this.selectedLanguage = this.currentUser.language
      ? this.currentUser.language
      : "en";
  },
  methods: {
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 650;
      if (this.isMobile) {
        this.globalStore.setIsMobile(true);
      } else {
        this.globalStore.setIsMobile(false);
      }
    },
    async changedLanguage(language) {
      const { data } = await UsersService.changeLanguage({
        user_id: this.currentUser.email,
        new_language: language,
      });
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.mainContentArea {
  width: calc(100vw - 200px);
  padding: 0 30px;
  overflow: auto;
  /* max-height: calc(100vh - 90px); */
  /* height: fit-content; */
}
.mainContentArea.sidebar-minimized {
  width: calc(100vw - 65px);
}
@media (max-width: 650px) {
  .mainContentArea {
    width: calc(100% - 30px);
    padding: 15px;
  }
}
@media (max-width: 1200px) {
  /* .mainContentArea {
    width: calc(100vw - 15rem);
  } */
}

.mobileMainContentArea {
  padding: 0 15px;
  margin-bottom: 20px;
  /* overflow:auto;  */
}
.sidebar-minimized.newsPage {
  /* overflow:auto; */
  max-height: calc(100vh - 100px);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
