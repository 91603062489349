<template>
  <div
    style="
      background: white;
      height: 100vh;
      outline: 1px solid rgb(195, 195, 195);
    "
    :class="['Sidebar', minimize ? 'minimized' : '']"
  >
    <div
      class="d-flex align-items-center gap-3"
      style="padding: 15px"
      :class="{ toggle: minimize }"
    >
      <button @click="toggleSidebar()" class="btn p-0 burger">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-list"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
          />
        </svg>
      </button>
      <img
        :src="logoSrc"
        alt=""
        style="max-height: 50px; width: 120px; object-fit: contain"
        :class="{ 'logo-margin': minimize }"
        class="pointer"
        @click="goToHome"
      />
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      "
    >
      <div>
        <div v-for="navItem in navbar" :key="navItem.id">
          <div
            v-for="item in navItem._children"
            :key="item.id"
            :class="{
              selectedNavItem: $route.path === item.to,
              textHidden: minimize,
            }"
            @click="item.to ? $router.push(item.to) : null"
          >
            <div class="navItem">
              <div class="icon-container">
                <div
                  v-if="minimize || (!minimize && item.isGroupTitle)"
                  v-html="item.icon"
                  class="sideBarIcon"
                ></div>
              </div>
              <span
                :style="
                  !(minimize || (!minimize && item.isGroupTitle))
                    ? 'margin-left: 10px;'
                    : ''
                "
              >
                {{ $t(item.name) }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!minimize">
        <div
          class="profile-item"
          style="position: relative"
          ref="profileItem"
          @click="showProfileOptions = true"
        >
          <div style="display: flex; align-items: center">
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div class="avatar_styles">
                <DefaultAvatarHome
                  :primaryColor="avatar.skinTone"
                  :selectedHair="avatar.selectedHair"
                  :topClothe="avatar.selectedTopClothes"
                  :bottomClothe="avatar.selectedBottomClothes"
                  :facialHair="avatar.selectedFacialHair"
                  :facialExpression="avatar.selectedExpression"
                  :facialHairPosition="facialHairPosition"
                  :shoes="avatar.selectedShoes"
                  :topClothesPosition="topClothesPosition"
                  :bottomClothesPosition="bottomClothesPosition"
                />
              </div>
            </div>
            <div class="profile-text">Profile</div>
          </div>
          <i
            class="fa fa-chevron-up"
            style="color: #c3c3c3; font-size: 12px"
          ></i>
          <div
            v-if="showProfileOptions"
            class="profile-item-options fadeInLeft animatedbottom"
          >
            <div class="profile-item-options-item" @click="goToProfilePage">
              <i
                class="fa fa-user"
                style="width: 22px; height: 22px; font-size: 20px"
              ></i>
              <span>{{ $t("Profile") }}</span>
            </div>
            <div
              class="profile-item-options-item"
              ref="languageSwitch"
              @click="showOptions = true"
            >
              <i
                class="fa fa-globe"
                style="width: 22px; height: 22px; font-size: 20px"
              ></i
              ><span>{{ $t("Language") }} </span>
            </div>
            <div
              class="profile-item-options-item"
              @click="logout"
              data-cy="logout"
            >
              <i
                class="fa fa-sign-out"
                style="
                  color: #c3c3c3;
                  width: 22px;
                  height: 22px;
                  font-size: 20px;
                "
              />
              <span>
                {{ $t("Logout") }}
              </span>
            </div>
          </div>
          <div
            v-if="showOptions"
            v-for="(lang, index) in languages"
            :key="lang"
            class="animated fadeInLeft fadeOutLeft langOptionContainer"
            :style="{
              background: 'white',
              position: 'absolute',
              left: 300 + 'px',
              bottom: 80 + index * 60 + 'px',
              width: '50px',
              height: '50px',
              borderRadius: '50%',
              zIndex: 999999999,
            }"
            style=""
          >
            <div @click="changedLanguage(lang.value)">
              <div class="langOption">
                {{ lang.value.toUpperCase() }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="minimize" class="logout" @click="logout" data-cy="logout">
        <i class="fa fa-sign-out" style="color: #c3c3c3; font-size: 20px" />
        <div class="toggled"></div>
      </div>
    </div>
  </div>
</template>

<script>
import nav from "./_nav";
import navNested from "./_navNested";
import { ActiveUser } from "../services/user";
import { mapGetters, mapState } from "vuex";
import { UsersService } from "@/services/users";
import { ProfileService } from "@/services/profile";
import { useAvatarStore } from "../stores/AvatarStore";
import { useUserStore } from "@/stores/UserStore";
import allPermissions from "../constants/permissions";
import DefaultAvatarHome from "../views/home/components/DefaultAvatarHome.vue";
import { toHandlers } from "vue";
import { mapWritableState } from "pinia";

export default {
  name: "TheSidebar",
  nav,
  allPermissions,
  components: {
    DefaultAvatarHome,
  },
  data() {
    return {
      testTrainingOrg: ["667ebaa7c47e7e78017db1e9", "668652f610eb8fc99fb94db1"],
      avatarStore: useAvatarStore(),
      userStore: useUserStore(),
      minimized: false,
      navNested: navNested,
      currentUser: null,
      navbar: nav,
      possiblePermissions: allPermissions,
      showOptions: false,
      languages: [
        { value: "en", label: "English" },
        { value: "fr", label: "Français" },
        { value: "de", label: "Deutsch" },
      ],
      selectedLanguage: "",
      allowedRoutes: {
        Home: true,
        Agenda: true,
        Plan: true,
        "Action Plan": true,
        "Training Plan": true,
        Calendar: true,
        News: true,
        Reporting: true,
        Challenges: true,
        Organization: true,
        Reporting: true,
        "Role Management": true,
      },

      topClothesPosition: "108px",
      bottomClothesPosition: "23px",
      showProfileOptions: false,
    };
  },
  props: {
    isMobile: Boolean,
  },
  watch: {
    // Watch for changes in route
    $route(to, from) {
      this.checkRouteAccess();
    },
  },
  async created() {
    await this.getPermissions();
    await this.getAvatar();
    this.currentUser = this.userData;
    this.$i18n.locale = this.currentUser.language
      ? this.currentUser.language
      : "en";
    this.selectedLanguage = this.currentUser.language
      ? this.currentUser.language
      : "en";
    this.clickOutsideListener = (event) => {
      if (
        this.$refs.languageSwitch &&
        !this.$refs.languageSwitch.contains(event.target)
      ) {
        this.showOptions = false;
      }
    };
    this.clickOutsideListener = (event) => {
      if (
        this.$refs.profileItem &&
        !this.$refs.profileItem.contains(event.target)
      ) {
        this.showProfileOptions = false;
        this.showOptions = false;
      }
    };
    document.addEventListener("click", this.clickOutsideListener);
    document.addEventListener("click", this.clickOutsideListener);
    this.updateSideBarMinimizeState();
    this.checkRouteAccess();
  },

  async mounted() {
    this.$watch(
      () => this.$route.path,
      () => {
        this.updateSideBarMinimizeState();
      }
    );
    window.addEventListener("resize", this.updateSideBarMinimizeState);
    this.updateSideBarMinimizeState();
  },
  beforeUnmount() {
    document.removeEventListener("click", this.clickOutsideListener);
    window.removeEventListener("resize", this.updateSideBarMinimizeState);
  },
  computed: {
    ...mapState({
      permissions: (state) => state.permissions,
      userData: (state) => state.userData,
      isAdmin: (state) => state.isAdmin,
    }),
    ...mapWritableState(useAvatarStore, {
      avatar: "avatar",
      topClothesPosition: "topClothesPosition",
      bottomClothesPosition: "bottomClothesPosition",
    }),
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
    logoSrc() {
      return this.minimize ? "/img/Favicon.svg" : "/img/ForvisMazars-Logo.png";
    },
    // canSeeTraining() {
    //   // check this.userData.current_organization._id if in testTrainingOrg
    //   return this.testTrainingOrg.includes(
    //     this.userData.current_organization._id
    //   );
    // },
  },
  methods: {
    toggleSidebar() {
      this.$store.commit("set", ["sidebarMinimize", !this.minimize]);
      this.minimized = !this.minimized;
    },
    async getAvatar() {
      try {
        const { data, res } = await ProfileService.get_avatar({
          user: this.userData.email,
        });
        // if (data.success) {
        //   this.avatarStore.setAvatarValues(data.data.avatar_data);
        // } else {
        //   this.setAvatarValues({});
        // }
        if (res.status != "200") {
          this.setAvatarValues({});
        } else {
          this.setAvatarValues(data.data.avatar_data);
        }
      } catch (error) {
        this.setAvatarValues({});
        console.error("error getting avatar", error);
      } finally {
      }
    },
    setAvatarValues(av = {}) {
      const isObject = (val) => typeof val === "object";
      const {
        selectedExpression,
        selectedHair,
        selectedFacialHair,
        selectedBottomClothes,
        selectedShoes,
        skinTone,
        selectedTopClothes,
        ...rest
      } = av;
      this.avatar = { ...rest };
      this.avatar.selectedExpression = isObject(selectedExpression)
        ? selectedExpression
        : { style: "facial_expression_3", color: "#FFB176" };
      this.avatar.selectedShoes = isObject(selectedShoes)
        ? selectedShoes
        : "shoes_1";
      this.avatar.selectedTopClothes = selectedTopClothes || "clothe_13";
      this.avatar.selectedBottomClothes = isObject(selectedBottomClothes)
        ? selectedBottomClothes
        : "bottom_1";
      this.avatar.skinTone = skinTone || "#FFB176";
      this.avatar.selectedHair = isObject(selectedHair)
        ? selectedHair
        : { style: "", color: "" };
      this.avatar.selectedFacialHair = isObject(selectedFacialHair)
        ? selectedFacialHair
        : { style: "", color: "" };
    },
    checkRouteAccess() {
      const hasAccess = this.hasAccessToRoute(this.$route.name);
      if (!hasAccess) {
        this.$router.push("/home");
      }
    },
    hasAccessToRoute(routePath) {
      return this.allowedRoutes[routePath] !== false;
    },
    async getPermissions() {
      const permissionRouteMap = {
        viewOrganizationPage: "Organization",
        viewActionsPage: "Action Plan",
        viewAgendaPage: "Agenda",
        viewTrainingPage: "Training Plan",
        viewReportingPage: "Reporting",
        viewChallengesPage: "Challenges",
        viewCommunicationPage: "News",
      };
      this.userStore.currentPermissions[0].forEach((section) => {
        section.permissions.forEach((permission) => {
          if (
            permission.name.startsWith("view") &&
            permissionRouteMap[permission.name]
          ) {
            this.allowedRoutes[permissionRouteMap[permission.name]] =
              permission.status;
          }
        });
      });
      function filterNavbar(navbar, allowedRoutes, isUserAdmin) {
        return navbar.map((navConfig) => {
          if (navConfig._name === "CSidebarNav") {
            let filteredChildren = navConfig._children.filter((child) => {
              const isAdminOnly = child.isAdminOnly || false;
              const isPublic = child.type === "public";
              if (isPublic) {
                return true;
              }
              if (child._name === "CSidebarNavItem" && child.name) {
                return (
                  allowedRoutes[child.name] !== false &&
                  (!isAdminOnly || isUserAdmin)
                );
              }
              return true;
            });

            return {
              ...navConfig,
              _children: filteredChildren,
            };
          }

          return navConfig;
        });
      }

      this.navbar = filterNavbar(
        this.navbar,
        this.allowedRoutes,
        this.userStore.isAdminUser
      );
    },
    goToProfilePage() {
      this.$router.push("/profile");
    },
    updateSideBarMinimizeState() {
      if (this.$route.path === "/action-plan") {
        this.minimized = true;
        this.$store.commit("set", ["sidebarMinimize", true]);
      } else {
        if (window.innerWidth <= 1123) {
          this.$store.commit("set", ["sidebarMinimize", true]);
        } else {
          this.$store.commit("set", ["sidebarMinimize", false]);
        }
      }
    },
    goToHome() {
      this.$router.push("/");
    },
    async changedLanguage(language) {
      const { data } = await UsersService.changeLanguage({
        user_id: this.currentUser.email,
        new_language: language,
      });
      window.location.reload();
    },
    logout() {
      this.$store.commit("setLoginStatus", false);
      ActiveUser.clear();
      this.$router.replace({ name: "Login" });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Kantumruy+Pro:ital,wght@0,100..700;1,100..700&display=swap");

.Sidebar {
  font-family: "Kantumruy Pro", sans-serif;
  font-weight: 500;
  transition: width 0.3s ease;
  width: 200px !important;
  display: flex;
  flex-direction: column;
}
.Sidebar.minimized {
  width: 60px !important;
}

@media (max-width: 822px) {
  .Sidebar {
    width: 190px !important;
  }
  .navItem:hover {
    width: 190px !important;
  }
  .navItem {
    gap: 0 !important;
  }
}
.Sidebar .flex {
  transition: margin-top 0.3s ease;
}
.navItem {
  position: relative;
  padding: 10px 10px 10px 20px;
  display: flex;
  gap: 10px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 3;
}

.navItem i {
  color: #0071ce;
}
::v-deep .navItem:hover .sideBarIcon svg path {
  fill: white;
}

.textHidden span {
  display: none;
}
.textHidden:hover span {
  margin-left: 20px;
  display: block;
}

.navItem:hover {
  background: #0071ce;
  color: white;
  width: 200px;
}

.selectedNavItem {
  background: #e7f0fa;
  border-left: 5px solid #0071ce;
}

.toggle {
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.logo-margin {
  max-height: 20px !important;
  object-fit: contain;
}

.profile-item {
  font-size: 14px;
  padding: 5px 20px 5px 5px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.profile-item:hover {
  background: #e7f0fa;
}

.avatar_styles {
  transform: scale(0.8);
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid rgb(195, 195, 195);
  height: 60px;
  width: 60px;
  cursor: pointer;
  margin-left: 8px;
  margin-right: 10px;
}

.profile-text {
  font-family: "Kantumruy Pro", sans-serif;
  color: gray;
  font-size: 16px;
}
.profile-item-options {
  color: #c3c3c3;
  background: white;
  border: 1px solid rgb(195, 195, 195);
  position: absolute;
  bottom: 70px;
  left: 120px;
  z-index: 999999999;
}
.profile-item-options-item {
  padding: 15px 35px;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.profile-item-options-item:hover {
  background: #0071ce;
  color: white;
}

.langOption {
  margin-top: 5px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #808285;
  cursor: pointer;
  padding: 10px;
  list-style: none;
  display: flex;
  gap: 5px;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animated:hover {
  background: #e0e0e0;
}
.langOptionContainer {
  border: 1px solid rgb(195, 195, 195);
}
.langOptionContainer:hover {
  background: #ebe9e9 !important;
}
.toggled {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.logout {
  color: #c3c3c3;
  font-size: 14px;
  padding: 20px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.3s;
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
}
.logout:hover {
  background-color: #3471da;
  color: white;
  height: 55px;
}

.logout:hover .fa {
  color: white !important;
}

.animatedbottom {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.icon-container {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
