const allPermissions = [
  {
    name: "view_organization_page",
    title: "View Organization Page",
    checked: false,
    group: "Organizations",
  },
  {
    name: "view_action_plan_page",
    title: "View Actions Page",
    checked: false,
    group: "Actions",
  },
  {
    name: "view_agenda_page",
    title: "View Agenda Page",
    checked: false,
    group: "Agenda",
  },
  {
    name: "view_communication_page",
    title: "View Communication Page",
    checked: false,
    group: "Communication",
  },
  {
    name: "view_training_plan_page",
    title: "View Training Page",
    checked: false,
    group: "Training",
  },
];

export default allPermissions;
