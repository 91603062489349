export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavItem",
        type: "route",
        name: "Home",
        to: "/home",
        icon: "fa fa-house",
        meta: {
          isActive: false,
        },
      },
      {
        type: "object",
        name: "Plan",
        icon: "fa fa-check-circle",
        children: [
          {
            _name: "CSidebarNavItem",
            name: "Action Plan",
            to: "/action-plan",
            icon: "fa fa-check-circle",
          },
          {
            _name: "CSidebarNavItem",
            _attrs: {
              "data-cy": "files-link",
            },
            name: "Training Plan",
            to: "/training-plan",
            icon: "fa fa-graduation-cap",
          },
          {
            _name: "CSidebarNavItem",
            _attrs: {
              "data-cy": "lazytable-link",
            },
            name: "Agenda",
            to: "/agenda",
            icon: "fa fa-calendar",
          },
        ],
      },
      {
        _name: "CSidebarNavItem",
        type: "route",
        name: "News",
        to: "/communication",
        icon: "fa fa-newspaper",
      },
      {
        _name: "CSidebarNavItem",
        type: "route",
        name: "Reporting",
        to: "/reporting",
        icon: "fa fa-bar-chart",
      },
      {
        type: "object",
        name: "Admin",
        icon: "fa fa-house",
        children: [
          {
            _name: "CSidebarNavItem",
            name: "News",
            to: "/news",
            icon: "fa fa-medal",
          },
          {
            _name: "CSidebarNavItem",
            name: "Challenges",
            to: "/challenges",
            icon: "fa fa-medal",
          },
          {
            _name: "CSidebarNavItem",
            name: "Organization",
            to: "/organization",
            icon: "fa fa-sitemap",
          },
          {
            _name: "CSidebarNavItem",
            name: "Role Management",
            to: "/role-management",
            icon: "fa fa-users-cog",
          },
        ],
      },
      {
        _name: "CSidebarNavItem",
        type: "route",
        name: "Guide",
        to: "/guide",
        icon: "fa fa-house",
        meta: {
          isActive: false,
        },
      },
    ],
  },
];
