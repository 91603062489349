<template>
  <div>
    <div
      style="
        background: white;
        height: 60px;
        border-bottom: 2px solid lightgray;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
      "
    >
      <button @click="drawer = true" class="btn p-0 burger">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-list"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
          />
        </svg>
      </button>
      <div class="avatar_styles shadow-lg" @click="$router.push('/profile')">
        <DefaultAvatarHome
          :primaryColor="avatar.skinTone"
          :selectedHair="avatar.selectedHair"
          :topClothe="avatar.selectedTopClothes"
          :bottomClothe="avatar.selectedBottomClothes"
          :facialHair="avatar.selectedFacialHair"
          :facialExpression="avatar.selectedExpression"
          :facialHairPosition="facialHairPosition"
          :shoes="avatar.selectedShoes"
          :topClothesPosition="topClothesPosition"
          :bottomClothesPosition="bottomClothesPosition"
        />
      </div>
      <el-drawer direction="ltr" v-model="drawer" size="50%">
        <template #header>
          <div class="d-flex align-items-center gap-3" style="padding: 15px">
            <img
              :src="logoSrc"
              alt=""
              style="max-height: 50px; width: 120px; object-fit: contain"
              class="pointer"
              @click="goToHome"
            />
          </div>
        </template>
        <template #default>
          <TheMobileSidebar @closeDrawer="closeDrawer"
        /></template>
      </el-drawer>
    </div>
    <div class="ml-3 mt-4 mb-3"><OrganizationSelector /></div>
  </div>
</template>
<script>
import { images } from "../assets/Images/images";
import { UsersService } from "@/services/users";
import { OrganizationsService } from "@/services/organizations";
import DefaultAvatar from "../views/profile/DefaultAvatar.vue";
import { ProfileService } from "@/services/profile";
import DefaultAvatarHome from "../views/home/components/DefaultAvatarHome.vue";
import { ContentLoader } from "vue-content-loader";
import { mapGetters, mapState } from "vuex";
import { mapWritableState } from "pinia";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import { useGlobalStore } from "@/stores/store";
import TheMobileSidebar from "./TheMobileSidebar.vue";

export default {
  components: {
    OrganizationSelector,
    DefaultAvatar,
    DefaultAvatarHome,
    ContentLoader,
    TheMobileSidebar,
  },
  name: "Profile",
  data() {
    return {
      drawer: false,
      showOptions: false,
      loading: true,
      sidebarOpen: false,
      selectedTeam: null,
      teams: [],
      sidebarPosition: "50px",
      challenges: [
        images.challenges.star,
        images.challenges.medal,
        images.challenges.cup,
        images.challenges.medalBadge,
      ],
      bagdeObtained: [],
      displayedChallenges: [],
      remainingBadgeCount: 0,
      countObtainedBadges: 0,
      avatar: images.avatar.profileHome,
      name: "",
      orgName: "",

      selectedRole: null,
      roleOptions: [
        { value: "All", text: "all" },
        { value: "Collaborateur-Comptabilité", text: "accounting" },
        {
          value: "Conduite de changement-Equipe projet BETA TEST",
          text: "change_management",
        },
      ],
      userEmail: "",
      avatar: {
        selectedHair: {},
        skinTone: "",
        selectedExpression: {},
        selectedFacialHair: {},
        selectedTopClothes: "",
        selectedBottomClothes: "",
        selectedShoes: "",
      },
      topClothesPosition: "108px",
      bottomClothesPosition: "23px",
      userInfo: null,
      allJobDescriptions: [],
      showMobileDropdown: false,
      showLang: false,
      isActionPlanRoute: false,
    };
  },
  props: {
    something: Boolean,
  },
  computed: {
    ...mapWritableState(useGlobalStore, {
      navbar: "navbarItems",
    }),
    images() {
      return images;
    },
    ...mapState({
      userData: (state) => state.userData,
      includedOrgs: (state) => state.includedOrgs,
      currentOrganization: (state) => state.selectedOrg,
      jobsPoints: (state) => state.jobsPoints,
      selectedJob: (state) => state.selectedJob,
      userPoints: (state) => state.userData.total_points,
    }),

    countObtainedBadges() {
      return this.bagdeObtained.length ? this.bagdeObtained.length : 0;
    },
    displayJobDescription() {
      return this.selectedJobDescription;
    },
    logoSrc() {
      return this.minimize ? "/img/m_logo.png" : "/img/mz_logo.png";
    },
  },
  mounted() {
    this.checkRoute();
  },

  async created() {
    this.getUserInfo();
    await this.getAvatar();
    this.getJobs();
    this.updateDisplayChallenges();
    this.getBadgeObtained();
  },
  watch: {
    async something() {
      const userData = await UsersService.getActiveUserInfo();
      if (userData.data) {
        this.$store.commit("setUserData", userData.data);
        this.$store.commit("setOrgObj", userData.data.current_organization);
        this.$store.commit("setJobsPoints", userData.data.level_points);
        const firstEntry = Object.entries(userData.data.level_points)[0];
        this.$store.commit("setJob", firstEntry);
        this.$store.commit("setUserPoints", userData.data.total_points);
      }
      this.$parent.resetSomething();
    },
    $route() {
      this.checkRoute();
    },
  },
  methods: {
    closeDrawer() {
      this.drawer = false;
    },
    toggleSidebar() {
      this.$emit("toggleSidebar");
    },
    checkRoute() {
      this.isActionPlanRoute = this.$route.path === "/action-plan";
    },
    handleWindowResize() {
      if (this.showLang && window.innerWidth > 1119) {
        this.showLang = false;
      }
    },
    async changedLanguage(language) {
      await UsersService.changeLanguage({
        user_id: this.userData.email,
        new_language: language,
      });
      window.location.reload();
    },
    openLanguage() {
      this.showLang = !this.showLang;
    },
    getJobs() {
      this.loading = false;
    },
    getBadgeSvg(badgeId) {
      let img = this.images.challenges[badgeId];
      if (!img) return;
      // Retrieve SVG for the given badge ID
      return img;
    },
    getBadgeObtained() {
      this.bagdeObtained = this.userData.challenges.map((b) => b.badge);
      this.loading = false;
    },
    getProgressValue(userPoints) {
      const [currentPoints, totalPoints] = userPoints.split("/").map(Number);
      if (currentPoints === 0) return 0;

      const progress = (currentPoints / totalPoints) * 100;
      return progress;
    },
    updateDisplayChallenges() {
      const maxDisplay = 1;
      if (this.challenges.length > maxDisplay) {
        this.displayedChallenges = this.challenges.slice(0, maxDisplay);
        this.remainingBadgeCount = this.challenges.length - maxDisplay;
      } else {
        this.displayedChallenges = this.challenges;
        this.remainingBadgeCount = 0;
      }
    },

    goToProfile() {
      this.$router.push("/profile");
    },
    async getAvatar() {
      try {
        const { data, res } = await ProfileService.get_avatar({
          user: this.userEmail,
        });
        this.setAvatarValues(data.data.avatar_data);
      } catch (error) {
        this.setAvatarValues({});
        console.error("error getting avatar", error);
      } finally {
        this.loading = false;
      }
    },
    setAvatarValues(av = {}) {
      const isObject = (val) => typeof val === "object";
      const {
        selectedExpression,
        selectedHair,
        selectedFacialHair,
        selectedBottomClothes,
        selectedShoes,
        skinTone,
        selectedTopClothes,
        ...rest
      } = av;
      this.avatar = { ...rest };
      this.avatar.selectedExpression = isObject(selectedExpression)
        ? selectedExpression
        : { style: "facial_expression_3", color: "#FFB176" };
      this.avatar.selectedShoes = isObject(selectedShoes)
        ? selectedShoes
        : { style: "type_1" };
      this.avatar.selectedTopClothes = selectedTopClothes || "clothe_13";
      this.avatar.selectedBottomClothes = isObject(selectedBottomClothes)
        ? selectedBottomClothes
        : { style: "type_1" };
      this.avatar.skinTone = skinTone || "#FFB176";
      this.avatar.selectedHair = isObject(selectedHair)
        ? selectedHair
        : { style: "", color: "" };
      this.avatar.selectedFacialHair = isObject(selectedFacialHair)
        ? selectedFacialHair
        : { style: "", color: "" };
    },
    async getUserInfo() {
      this.userInfo = this.userData;
      this.name = this.userInfo.fullname;
      this.orgName = this.userInfo.organization;
      this.userEmail = this.userInfo.email;
      this.teams = this.userInfo.points.team.map((team) => ({
        organization_id: team.organization_name,
        organization_name: team.organization_name,
      }));
      this.selectedTeam = this.teams[0].organization_name;
      // await this.getAvatar();
    },

    toggleOptions() {
      this.showOptions = !this.showOptions;
    },
    async jobSelected(job) {
      const { data } = await OrganizationsService.change_job_desc({
        job_description: job[0],
      });
      this.toggleOptions();
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.avatar_styles {
  transform: scale(0.8);
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid rgb(195, 195, 195);
  height: 60px;
  width: 60px;
  cursor: pointer;
  margin-left: 8px;
}
.roleOption {
  padding: 10px;
  cursor: pointer;
}
.roleOption:hover {
  background: #f5f5f5;
}

.avatar_ribbon {
  border-radius: 100%;
  border: 1px;
  overflow: hidden;
  height: 65px;
  width: 65px;
  min-width: 65px;
  margin-right: 10px;
  cursor: pointer;
  outline: 2px solid lightgray;
  background-color: white;
}
.cards {
  display: flex;
  width: 225px;
  outline: 2px solid lightgray;
  height: 65px;
  background-color: white;
  padding: 15px;
}
.badges {
  background: transparent;
  border: none;
  margin-top: 20px;
}

.count_badges {
  width: 25px;
  height: 25px;
  background-color: white;
  position: absolute;
  top: 20px;
  right: 5px;
  border-radius: 50%;
  border: 1px solid #6381da;
  text-align: center;
  padding-top: 1px;
}
.badge-not-obtained {
  filter: grayscale(100%);
  opacity: 0.5;
}

.role {
  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}
</style>
