<template>
  <div style="background: white" class="Sidebar">
    <div
      style="
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 80vh;
      "
    >
      <div>
        <div v-for="navItem in navbar">
          <div
            v-for="item in navItem._children"
            class="navItem"
            :class="{
              selectedNavItem: $route.path === item.to,
            }"
            @click="handleRouteChange(item)"
          >
            <i
              v-if="item.isGroupTitle"
              :class="item.icon"
              style="width: 22px; font-size: 20px"
            />
            <span :style="!item.isGroupTitle ? 'margin-left: 33px;' : ''">
              {{ item.name }}
            </span>
          </div>
        </div>
      </div>
      <div v-if="!minimize">
        <div
          class="profile-item"
          style="position: relative"
          ref="profileItem"
          @click="showProfileOptions = true"
        >
          <div style="display: flex; align-items: center">
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div class="avatar_styles shadow-lg">
                <DefaultAvatarHome
                  :primaryColor="avatar.skinTone"
                  :selectedHair="avatar.selectedHair"
                  :topClothe="avatar.selectedTopClothes"
                  :bottomClothe="avatar.selectedBottomClothes"
                  :facialHair="avatar.selectedFacialHair"
                  :facialExpression="avatar.selectedExpression"
                  :facialHairPosition="facialHairPosition"
                  :shoes="avatar.selectedShoes"
                  :topClothesPosition="topClothesPosition"
                  :bottomClothesPosition="bottomClothesPosition"
                />
              </div>
            </div>
            <div class="profile-text">Profile</div>
          </div>
          <i
            class="fa fa-chevron-up"
            style="color: #c3c3c3; font-size: 12px"
          ></i>
          <div
            v-if="showProfileOptions"
            class="profile-item-options fadeInLeft animatedbottom"
          >
            <div class="profile-item-options-item" @click="goToProfilePage">
              <i
                class="fa fa-user"
                style="width: 22px; height: 22px; font-size: 20px"
              ></i>
              <span>{{ $t("Profile") }}</span>
            </div>
            <div
              class="profile-item-options-item"
              ref="languageSwitch"
              @click="showOptions = true"
            >
              <i
                class="fa fa-globe"
                style="width: 22px; height: 22px; font-size: 20px"
              ></i
              ><span>{{ $t("Language") }} </span>
              <div
                v-if="showOptions"
                v-for="(lang, index) in languages"
                :key="lang"
                class="animated fadeInLeft fadeOutLeft"
                :style="{
                  background: 'white',
                  position: 'absolute',
                  left: 190 + 'px',
                  bottom: 60 + index * 60 + 'px',
                  width: '50px',
                  height: '50px',
                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                  borderRadius: '50%',
                }"
              >
                <div @click="changedLanguage(lang.value)">
                  <div class="langOption">
                    {{ lang.value.toUpperCase() }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="profile-item-options-item"
              @click="logout"
              data-cy="logout"
            >
              <i
                class="fa fa-sign-out"
                style="
                  color: #c3c3c3;
                  width: 22px;
                  height: 22px;
                  font-size: 20px;
                "
              />
              <span>
                {{ $t("Logout") }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="minimize" class="logout" @click="logout" data-cy="logout">
        <i class="fa fa-sign-out" style="color: #c3c3c3; font-size: 20px" />
        <div class="toggled"></div>
      </div>
    </div>
  </div>
</template>

<script>
import nav from "./_nav";
import navNested from "./_navNested";
import { ActiveUser } from "../services/user";
import { mapGetters, mapState } from "vuex";
import { UsersService } from "@/services/users";
import { ProfileService } from "@/services/profile";

import allPermissions from "../constants/permissions";
import DefaultAvatarHome from "../views/home/components/DefaultAvatarHome.vue";
import { toHandlers } from "vue";

export default {
  name: "TheSidebar",
  nav,
  allPermissions,
  components: {
    DefaultAvatarHome,
  },
  data() {
    return {
      minimized: false,
      navNested: navNested,
      currentUser: null,
      navbar: nav,
      possiblePermissions: allPermissions,
      showOptions: false,
      languages: [
        { value: "en", label: "English" },
        { value: "fr", label: "Français" },
      ],
      selectedLanguage: "",
      allowedRoutes: {
        Home: true,
        Agenda: true,
        Plan: true,
        "Action Plan": true,
        "Training Plan": true,
        Calendar: true,
        News: true,
        Reporting: true,
        Challenges: true,
        Organization: true,
        Reporting: true,
        "Role Management": true,
      },
      avatar: {
        selectedHair: {},
        skinTone: "",
        selectedExpression: {},
        selectedFacialHair: {},
        selectedTopClothes: "",
        selectedBottomClothes: "",
        selectedShoes: "",
      },
      topClothesPosition: "108px",
      bottomClothesPosition: "23px",
      showProfileOptions: false,
    };
  },
  props: {
    isMobile: Boolean,
  },
  watch: {
    // Watch for changes in route
    $route(to, from) {
      this.checkRouteAccess();
    },
  },
  async created() {
    await this.getPermissions();
    await this.getAvatar();
    this.currentUser = this.userData;
    this.$i18n.locale = this.currentUser.language
      ? this.currentUser.language
      : "en";
    this.selectedLanguage = this.currentUser.language
      ? this.currentUser.language
      : "en";
    this.clickOutsideListener = (event) => {
      if (
        this.$refs.languageSwitch &&
        !this.$refs.languageSwitch.contains(event.target)
      ) {
        this.showOptions = false;
      }
    };
    this.clickOutsideListener = (event) => {
      if (!this.$refs.profileItem.contains(event.target)) {
        this.showProfileOptions = false;
        this.showOptions = false;
      }
    };
    document.addEventListener("click", this.clickOutsideListener);
    document.addEventListener("click", this.clickOutsideListener);
    this.updateSideBarMinimizeState();
    this.checkRouteAccess();
  },

  async mounted() {
    this.$watch(
      () => this.$route.path,
      () => {
        this.updateSideBarMinimizeState();
      }
    );
    window.addEventListener("resize", this.updateSideBarMinimizeState);
    this.updateSideBarMinimizeState();
  },
  beforeUnmount() {
    document.removeEventListener("click", this.clickOutsideListener);
    window.removeEventListener("resize", this.updateSideBarMinimizeState);
  },
  computed: {
    ...mapState({
      permissions: (state) => state.permissions,
      userData: (state) => state.userData,
      isUserAdmin: (state) => state.isUserAdmin,
    }),
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
    logoSrc() {
      return this.minimize ? "/img/m_logo.png" : "/img/mz_logo.png";
    },
  },
  methods: {
    handleRouteChange(item) {
      item.to ? this.$router.push(item.to) : null;
      this.$emit("closeDrawer");
    },
    toggleSidebar() {
      this.$store.commit("set", ["sidebarMinimize", !this.minimize]);
      this.minimized = !this.minimized;
    },
    async getAvatar() {
      try {
        const { data, res } = await ProfileService.get_avatar({
          user: this.userData.email,
        });
        this.setAvatarValues(data.data.avatar_data);
      } catch (error) {
        this.setAvatarValues({});
        console.error("error getting avatar", error);
      } finally {
      }
    },
    setAvatarValues(av = {}) {
      const isObject = (val) => typeof val === "object";
      const {
        selectedExpression,
        selectedHair,
        selectedFacialHair,
        selectedBottomClothes,
        selectedShoes,
        skinTone,
        selectedTopClothes,
        ...rest
      } = av;
      this.avatar = { ...rest };
      this.avatar.selectedExpression = isObject(selectedExpression)
        ? selectedExpression
        : { style: "facial_expression_3", color: "#FFB176" };
      this.avatar.selectedShoes = isObject(selectedShoes)
        ? selectedShoes
        : "shoes_1";
      this.avatar.selectedTopClothes = selectedTopClothes || "clothe_13";
      this.avatar.selectedBottomClothes = isObject(selectedBottomClothes)
        ? selectedBottomClothes
        : "bottom_1";
      this.avatar.skinTone = skinTone || "#FFB176";
      this.avatar.selectedHair = isObject(selectedHair)
        ? selectedHair
        : { style: "", color: "" };
      this.avatar.selectedFacialHair = isObject(selectedFacialHair)
        ? selectedFacialHair
        : { style: "", color: "" };
    },
    checkRouteAccess() {
      const hasAccess = this.hasAccessToRoute(this.$route.name);
      if (!hasAccess) {
        this.$router.push("/home");
      }
    },
    hasAccessToRoute(routePath) {
      return this.allowedRoutes[routePath] !== false;
    },
    async getPermissions() {
      function normalizeRouteName(routeName) {
        const nameToReturn = routeName.toLowerCase().replace(/\s+/g, "_");
        return nameToReturn;
      }

      this.possiblePermissions.forEach((permission) => {
        const normalizedRouteName = normalizeRouteName(permission.title);
        const permissionKey = `${normalizedRouteName}`;

        if (this.permissions[permissionKey]) {
          permission.checked = true;
        }
      });

      Object.keys(this.allowedRoutes).forEach((route) => {
        const normalizedRouteName = normalizeRouteName(route);
        const permissionKey = `view_${normalizedRouteName}_page`;

        this.possiblePermissions.forEach((permission) => {
          if (permission.name === permissionKey) {
            this.allowedRoutes[route] = permission.checked;
          }
        });
      });
      if (!this.isUserAdmin) {
        this.allowedRoutes["Role Management"] = false;
      }
      function filterNavbar(navbar, allowedRoutes, isUserAdmin) {
        return navbar.map((navConfig) => {
          if (navConfig._name === "CSidebarNav") {
            let filteredChildren = navConfig._children.filter((child) => {
              // Check if the item is for admin only and whether the user is an admin
              const isAdminOnly = child.isAdminOnly || false;
              if (child._name === "CSidebarNavItem" && child.name) {
                return (
                  allowedRoutes[child.name] !== false &&
                  (!isAdminOnly || isUserAdmin)
                );
              }
              return true;
            });

            return {
              ...navConfig,
              _children: filteredChildren,
            };
          }
          return navConfig;
        });
      }

      this.navbar = filterNavbar(
        this.navbar,
        this.allowedRoutes,
        this.isUserAdmin
      );
    },
    goToProfilePage() {
      this.$router.push("/profile");
    },
    updateSideBarMinimizeState() {
      if (this.$route.path === "/action-plan") {
        this.minimized = true;
        this.$store.commit("set", ["sidebarMinimize", true]);
      } else {
        if (window.innerWidth <= 1123) {
          this.$store.commit("set", ["sidebarMinimize", true]);
        } else {
          this.$store.commit("set", ["sidebarMinimize", false]);
        }
      }
    },
    goToHome() {
      this.$router.push("/");
    },
    async changedLanguage(language) {
      const { data } = await UsersService.changeLanguage({
        user_id: this.currentUser.email,
        new_language: language,
      });
      window.location.reload();
    },
    logout() {
      this.$store.commit("setLoginStatus", false);
      ActiveUser.clear();
      this.$router.replace({ name: "Login" });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Kantumruy+Pro:ital,wght@0,100..700;1,100..700&display=swap");
.Sidebar {
  font-family: "Kantumruy Pro", sans-serif;
  font-weight: 500;
  transition: width 0.3s ease;
  display: flex;
  flex-direction: column;
}

.navItem {
  position: relative;
  padding: 10px 10px 10px 5px;
  display: flex;
  gap: 10px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 99999999;
}

.navItem i {
  color: #0071ce;
}
.navItem:hover i {
  color: white;
}

.textHidden span {
  display: none;
}
.textHidden:hover span {
  margin-left: 20px;
  display: block;
}

.navItem:hover {
  background: #0071ce;
  color: white;
  width: 200px;
}

.selectedNavItem {
  background: #e7f0fa;
  color: #0071ce;
  border-left: 5px solid #0071ce;
}

.toggle {
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.logo-margin {
  max-height: 20px !important;
  object-fit: contain;
}

.profile-item {
  font-size: 14px;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.profile-item:hover {
  background: #e7f0fa;
}

.avatar_styles {
  transform: scale(0.8);
  border-radius: 50%;
  overflow: hidden;
  border: 1px;
  height: 60px;
  width: 60px;
  cursor: pointer;
  margin-left: 8px;
  margin-right: 10px;
}

.profile-text {
  font-family: "Kantumruy Pro", sans-serif;
  color: gray;
  font-size: 16px;
}
.profile-item-options {
  color: #c3c3c3;
  background: white;
  border: 2px solid #efefef;
  position: absolute;
  bottom: 70px;
  left: 120px;
  z-index: 999999999;
}
.profile-item-options-item {
  padding: 15px 35px;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.profile-item-options-item:hover {
  background: #0071ce;
  color: white;
}

.langOption {
  margin-top: 5px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #808285;
  cursor: pointer;
  padding: 10px;
  list-style: none;
  display: flex;
  gap: 5px;
}
.toggled {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.logout {
  color: #c3c3c3;
  font-size: 14px;
  transition: background-color 0.2s, color 0.3s;
  display: flex;
  justify-content: center;
}
</style>

<style>
.el-drawer .el-drawer__body {
  padding: 0 1px 0 0 !important;
}
</style>
