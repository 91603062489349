<template>
  <div class="header-container">
    <div class="ml-5"><OrganizationSelector /></div>
    <div style="display: flex; gap: 20px; align-items: center">
      <div class="avatar_ribbon" @click="goToProfile">
        <ContentLoader
          view-box="0 0 400 400"
          v-if="loading"
          :speed="2"
          primaryColor="rgb(230, 230, 230)"
          secondaryColor="rgb(240, 240, 240)"
        >
          <circle cx="200" cy="200" r="200" />
        </ContentLoader>
        <DefaultAvatarHome
          v-else
          :primaryColor="avatar.skinTone"
          :selectedHair="avatar.selectedHair"
          :topClothe="avatar.selectedTopClothes"
          :bottomClothe="avatar.selectedBottomClothes"
          :facialHair="avatar.selectedFacialHair"
          :facialExpression="avatar.selectedExpression"
          :facialHairPosition="facialHairPosition"
          :shoes="avatar.selectedShoes"
          :topClothesPosition="topClothesPosition"
          :bottomClothesPosition="bottomClothesPosition"
        />
      </div>
      <div class="cards">
        <ContentLoader
          view-box="0 0 735 207"
          v-if="loading"
          :speed="2"
          primaryColor="rgb(230, 230, 230)"
          secondaryColor="rgb(240, 240, 240)"
        ></ContentLoader>
        <div v-else style="display: flex; flex-direction: column">
          <div class="d-flex justify-content-between">
            <CCardSubtitle class="text-muted d-flex">
              <span style="font-weight: bold"> {{ $t("Role") }}: </span>
              <ContentLoader
                view-box="0 0 800 90"
                width="70px"
                v-if="loading"
                :speed="2"
                primaryColor="rgb(230, 230, 230)"
                secondaryColor="rgb(240, 240, 240)"
              />
              <div ref="orgSelectorContainer" v-else>
                <div style="cursor: pointer" @click="toggleOptions">
                  <h4
                    style="font-size: 12px; line-height: 18px"
                    class="role ml-1 my-0"
                  >
                    {{
                      selectedJob && selectedJob[0] != "level_id"
                        ? $t(Object.values(selectedJob)[0])
                        : $t("No Role")
                    }}

                    <i v-if="selectedJob" class="fa fa-chevron-down"></i>
                  </h4>
                </div>

                <div
                  v-if="showOptions"
                  style="
                    background: white;
                    position: absolute;
                    top: 30px;
                    width: 150px;
                    border-radius: 5px;
                    z-index: 999999;
                  "
                >
                  <div v-for="(job, index) in jobsPoints" :key="index">
                    <div @click="jobSelected(job)" class="roleOption">
                      {{ job.job }} - {{ job.level }}
                    </div>
                  </div>
                </div>
              </div>
            </CCardSubtitle>
          </div>
          <div>
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="`Points: ${
                userStore.userPoints
              }, Progress: ${getProgressValue(userStore.userPoints).toFixed(
                2
              )}%`"
            >
              <div style="display: flex; align-items: center">
                <span
                  style="margin-right: 10px; font-size: 12px"
                  class="text-muted"
                  >Actions:</span
                >
                <CProgress style="height: 3px; flex-grow: 1">
                  <CProgressBar
                    :value="getProgressValue(userStore.userPoints)"
                  />
                </CProgress>
              </div>
            </el-tooltip>

            <el-tooltip
              class="box-item"
              effect="dark"
              :content="`Points: ${userStore.userTrainingProgress.current} / ${
                userStore.userTrainingProgress.total
              }, Progress: ${calculateProgress(
                userStore.userTrainingProgress
              ).toFixed(2)} %`"
            >
              <div style="display: flex; align-items: center">
                <span
                  style="margin-right: 10px; font-size: 12px"
                  class="text-muted"
                  >Training:</span
                >
                <CProgress style="height: 3px; flex-grow: 1">
                  <CProgressBar
                    :value="calculateProgress(userStore.userTrainingProgress)"
                  />
                </CProgress>
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div class="cards">
        <ContentLoader
          view-box="0 0 730 207"
          v-if="loading"
          :speed="2"
          primaryColor="rgb(230, 230, 230)"
          secondaryColor="rgb(240, 240, 240)"
        ></ContentLoader>
        <div v-else style="display: flex; flex-direction: column">
          <div class="d-flex justify-content-between">
            <CCardSubtitle class="text-muted d-flex">
              <span class=""> {{ $t("Team") }}: </span>
            </CCardSubtitle>
          </div>

          <div style="width: 150px">
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="`Points:
            ${userData.total_progress}, Progress: ${getProgressValue(
                userData.total_progress
              ).toFixed(2)} %`"
            >
              <div style="display: flex; align-items: center">
                <span
                  style="margin-right: 10px; font-size: 12px"
                  class="text-muted"
                  >Actions:</span
                >
                <CProgress style="height: 3px; flex-grow: 1">
                  <CProgressBar
                    :value="getProgressValue(userData.total_progress)"
                  />
                </CProgress>
              </div>
            </el-tooltip>
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="`Points: ${userData.training_team_points.current} / ${
                userData.training_team_points.total
              }, Progress: ${calculateProgress(
                userData.training_team_points
              ).toFixed(2)} %`"
            >
              <div style="display: flex; align-items: center">
                <span
                  style="margin-right: 10px; font-size: 12px"
                  class="text-muted"
                  >Training:</span
                >
                <CProgress style="height: 3px; flex-grow: 1">
                  <CProgressBar
                    :value="calculateProgress(userData.training_team_points)"
                  />
                </CProgress>
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
      <CCard class="badges">
        <ContentLoader
          v-if="loading"
          view-box="0 0 400 450"
          width="56"
          :speed="2"
          primaryColor="rgb(230, 230, 230)"
          secondaryColor="rgb(240, 240, 240)"
        />
        <div v-else>
          <div v-if="bagdeObtained.length > 0">
            <div
              v-html="getBadgeSvg(bagdeObtained[0])"
              :class="{
                bronze_badge: bagdeObtained[0].color === 'Bronze',
                silver_badge: bagdeObtained[0].color === 'Silver',
                gold_badge: bagdeObtained[0].color === 'Gold',
                teal_badge: bagdeObtained[0].color === 'Teal',
                light_green_badge: bagdeObtained[0].color === 'Light Green',
              }"
            ></div>
          </div>
          <div
            v-else
            v-for="(badge, badgeId) in displayedChallenges"
            :key="badgeId"
            v-html="badge"
            :class="{
              'badge-not-obtained': !bagdeObtained[index],
              'badge-obtained': bagdeObtained[index],
            }"
          ></div>
          <div
            class="count_badges pointer"
            @click="goToProfile"
            v-c-tooltip="{
              content: $t('See more'),
              placement: 'bottom',
            }"
            style="color: #6381da"
          >
            {{ countObtainedBadges }}
          </div>
        </div>
      </CCard>
    </div>
  </div>
</template>
<script>
import { images } from "../assets/Images/images";
import { UsersService } from "@/services/users";
import { OrganizationsService } from "@/services/organizations";
import { ProfileService } from "@/services/profile";
import DefaultAvatarHome from "../views/home/components/DefaultAvatarHome.vue";
import { ContentLoader } from "vue-content-loader";
import { mapGetters, mapState } from "vuex";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import { useGlobalStore } from "@/stores/store";
import { useUserStore } from "@/stores/UserStore";
import { updateMyData } from "@/utils/helpers";
import { mapWritableState } from "pinia";
import { useAvatarStore } from "@/stores/AvatarStore";

export default {
  components: {
    OrganizationSelector,
    DefaultAvatarHome,
    ContentLoader,
  },
  name: "Profile",
  data() {
    return {
      userStore: useUserStore(),
      globalStore: useGlobalStore(),
      avatarStore: useAvatarStore(),
      showOptions: false,
      loading: true,
      sidebarOpen: false,
      selectedTeam: null,
      teams: [],
      sidebarPosition: "50px",
      challenges: [
        images.challenges.star,
        images.challenges.medal,
        images.challenges.cup,
        images.challenges.medalBadge,
      ],
      bagdeObtained: [],
      displayedChallenges: [],
      remainingBadgeCount: 0,
      countObtainedBadges: 0,
      name: "",
      orgName: "",

      selectedRole: null,
      roleOptions: [
        { value: "All", text: "all" },
        { value: "Collaborateur-Comptabilité", text: "accounting" },
        {
          value: "Conduite de changement-Equipe projet BETA TEST",
          text: "change_management",
        },
      ],
      userEmail: "",

      topClothesPosition: "108px",
      bottomClothesPosition: "23px",
      userInfo: null,
      allJobDescriptions: [],
      showMobileDropdown: false,
      showLang: false,
      isActionPlanRoute: false,
    };
  },
  props: {
    something: Boolean,
  },
  computed: {
    images() {
      return images;
    },
    ...mapState({
      userData: (state) => state.userData,
      includedOrgs: (state) => state.includedOrgs,
      currentOrganization: (state) => state.selectedOrg,
      jobsPoints: (state) => state.jobsPoints,
      selectedJob: (state) => state.selectedJob,
      userPoints: (state) => state.userData.total_points,
    }),
    ...mapWritableState(useGlobalStore, {
      updatedProgress: "updatedProgress",
    }),
    ...mapWritableState(useAvatarStore, {
      avatar: "avatar",
      topClothesPosition: "topClothesPosition",
      bottomClothesPosition: "bottomClothesPosition",
    }),
    countObtainedBadges() {
      return this.bagdeObtained.length ? this.bagdeObtained.length : 0;
    },
    displayJobDescription() {
      return this.selectedJobDescription;
    },
  },
  mounted() {
    this.checkRoute();
    window.addEventListener("resize", this.handleWindowResize);
    this.clickOutsideListener = (event) => {
      if (!this.$refs.orgSelectorContainer.contains(event.target)) {
        this.showOptions = false;
      }
    };
    document.addEventListener("click", this.clickOutsideListener);
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
    document.removeEventListener("click", this.clickOutsideListener);
  },
  async created() {
    this.getUserInfo();
    await this.getAvatar();
    this.getJobs();
    this.updateDisplayChallenges();
    this.getBadgeObtained();
  },
  watch: {
    async updatedProgress(val) {
      if (val) {
        updateMyData();
      }
      // this.$parent.resetSomething();
    },
    $route() {
      this.checkRoute();
    },
    userData() {
      this.updateDisplayChallenges();
      this.getBadgeObtained();
    },
  },
  methods: {
    calculateProgress(progress) {
      if (
        !progress ||
        typeof progress.current !== "number" ||
        typeof progress.total !== "number"
      ) {
        return 0;
      }

      const { current, total } = progress;
      if (total === 0) {
        return 0;
      }

      const percentage = (current / total) * 100;
      return percentage;
    },
    checkRoute() {
      this.isActionPlanRoute = this.$route.path === "/action-plan";
    },
    handleWindowResize() {
      if (this.showLang && window.innerWidth > 1119) {
        this.showLang = false;
      }
    },
    async changedLanguage(language) {
      await UsersService.changeLanguage({
        user_id: this.userData.email,
        new_language: language,
      });
      window.location.reload();
    },
    openLanguage() {
      this.showLang = !this.showLang;
    },
    getJobs() {
      this.loading = false;
    },
    getBadgeSvg(badgeId) {
      let img = this.images.challenges[badgeId];
      if (!img) return;
      // Retrieve SVG for the given badge ID
      return img;
    },
    getBadgeObtained() {
      this.bagdeObtained = this.userData.challenges.map((b) => b.badge);
      this.loading = false;
    },
    getProgressValue(userPoints) {
      const [currentPoints, totalPoints] = userPoints.split("/").map(Number);
      if (currentPoints === 0) return 0;

      const progress = (currentPoints / totalPoints) * 100;
      return progress;
    },
    updateDisplayChallenges() {
      const maxDisplay = 1;
      if (this.challenges.length > maxDisplay) {
        this.displayedChallenges = this.challenges.slice(0, maxDisplay);
        this.remainingBadgeCount = this.challenges.length - maxDisplay;
      } else {
        this.displayedChallenges = this.challenges;
        this.remainingBadgeCount = 0;
      }
    },

    goToProfile() {
      this.$router.push("/profile");
    },
    async getAvatar() {
      try {
        const { data, res } = await ProfileService.get_avatar({
          user: this.userEmail,
        });

        // if (data.success) {
        //   this.setAvatarValues(data.data.avatar_data);
        // } else {
        //   this.setAvatarValues({});
        // }
        if (res.status != "200") {
          this.setAvatarValues({});
        } else {
          this.setAvatarValues(data.data.avatar_data);
        }
      } catch (error) {
        this.setAvatarValues({});
        console.error("error getting avatar", error);
      } finally {
        this.loading = false;
      }
    },
    setAvatarValues(av = {}) {
      const isObject = (val) => typeof val === "object";
      const {
        selectedExpression,
        selectedHair,
        selectedFacialHair,
        selectedBottomClothes,
        selectedShoes,
        skinTone,
        selectedTopClothes,
        ...rest
      } = av;
      this.avatar = { ...rest };
      this.avatar.selectedExpression = isObject(selectedExpression)
        ? selectedExpression
        : { style: "facial_expression_3", color: "#FFB176" };
      this.avatar.selectedShoes = isObject(selectedShoes)
        ? selectedShoes
        : { style: "type_1" };
      this.avatar.selectedTopClothes = selectedTopClothes || "clothe_13";
      this.avatar.selectedBottomClothes = isObject(selectedBottomClothes)
        ? selectedBottomClothes
        : { style: "type_1" };
      this.avatar.skinTone = skinTone || "#FFB176";
      this.avatar.selectedHair = isObject(selectedHair)
        ? selectedHair
        : { style: "", color: "" };
      this.avatar.selectedFacialHair = isObject(selectedFacialHair)
        ? selectedFacialHair
        : { style: "", color: "" };
    },
    async getUserInfo() {
      this.userInfo = this.userData;
      this.name = this.userInfo.fullname;
      this.orgName = this.userInfo.organization;
      this.userEmail = this.userInfo.email;
      this.teams = this.userInfo.points.team.map((team) => ({
        organization_id: team.organization_name,
        organization_name: team.organization_name,
      }));
      this.selectedTeam = this.teams[0].organization_name;
      // await this.getAvatar();
    },

    toggleOptions() {
      this.showOptions = !this.showOptions;
    },
    async jobSelected(job) {
      try {
        const { data } = await OrganizationsService.change_job_desc({
          job_description: job.job,
          level: job.level,
          role: job.role,
        });
        this.toggleOptions();
        if (data) {
          window.location.reload();
        }
      } catch (error) {
        console.error("Error updating job description:", error);
      }
    },
  },
};
</script>

<style scoped>
::v-deep .bronze_badge circle {
  fill: #e2a963;
}
::v-deep .bronze_badge path:first-child {
  fill: #de9336;
}

::v-deep .silver_badge circle {
  fill: #d3d3d3;
}
::v-deep .silver_badge path:first-child {
  fill: #b2aeae;
}
::v-deep .teal_badge circle {
  fill: #a2c1bf;
}
::v-deep .teal_badge path:first-child {
  fill: #53908b;
}
::v-deep .light_green_badge circle {
  fill: #dfffe0;
}
::v-deep .light_green_badge path:first-child {
  fill: #7feb45;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding: 0 20px 0 0;
  margin-bottom: 10px;
  position: sticky;
  top: 0;
  z-index: 3;
}
@media (max-width: 970px) {
  .header-container {
    height: fit-content;
    flex-direction: column-reverse;
    align-items: center;
    margin: 0 20px 20px 20px;
  }
}
.roleOption {
  padding: 10px;
  cursor: pointer;
}
.roleOption:hover {
  background: #f5f5f5;
}

.avatar_ribbon {
  border-radius: 100%;
  border: 1px;
  overflow: hidden;
  height: 65px;
  width: 65px;
  min-width: 65px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid rgb(195, 195, 195);
  background-color: white;
}

.cards {
  display: flex;
  width: max-content;
  border: 1px solid rgb(195, 195, 195);
  height: 65px;
  background-color: white;
  padding: 10px 15px 0 15px;
}
.badges {
  background: transparent;
  border: none;
  margin-top: 20px;
}

.count_badges {
  width: 25px;
  height: 25px;
  background-color: white;
  position: absolute;
  top: 40px;
  right: 1px;
  border-radius: 50%;
  border: 1px solid #6381da;
  text-align: center;
  padding-top: 1px;
}
.badge-not-obtained {
  filter: grayscale(100%);
  opacity: 0.5;
}

.role {
  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}
::v-deep .progress-bar {
  background-color: #3399ff;
}
</style>
