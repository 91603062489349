<template>
  <PageHeader
    logo-url="/img/mz_logo.png"
    app-name="web_adopt_web"
    :should-hide-brand-logo="true"
    :app-iconic-logo="''"
    :userName="userName"
    :handleLogout="logoutUser"
  />
</template>

<script>
  import {ActiveUser} from "@/services/user";

  export default {
    name: 'ThePageHeader',
    data() {
      return {
        isAuthenticated: ActiveUser.getToken(),
        userName: ActiveUser.get()?.email,
      }
    },
    methods: {
      logoutUser() {
        ActiveUser.clear()
        this.$router.replace({ name: 'Login' });
      },
    }
  };
</script>
