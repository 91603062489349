<template>
  <CFooter :fixed="false" class="align-items-center">
    <div>{{ $t('by') }} Mazars R&D &copy; 2019 - {{ currentYear }} Mazars</div>
    <div class="flex-1 d-flex flex-wrap justify-content-end small" v-if="links">
      <component
        :is="link.link ? 'a' : 'span'"
        v-for="link in links"
        :key="link.text"
        target="_blank"
        :href="link.link"
        class="ml-3"
      >
        <i class="fa mr-1" :class="link.icon" />
        {{ link.text }}
      </component>
    </div>
  </CFooter>
</template>

<script>
import { FooterService } from "@/services/footer";
import { ActiveUser } from "@/services/user";

export default {
  name: "TheFooter",
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
  data() {
    return {
      links: null,
      isAdministrator: ActiveUser.isAdministrator(),
    };
  },
  async mounted() {
    if (this.isAdministrator) {
      this.links = await FooterService.fetchLinks();
    }
  },
};
</script>
